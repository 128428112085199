import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  Grid,
  CardHeader,
  LinearProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconBox, VersioningApp } from "../components";
import {  useContext } from "react";
import { AuthContext, CaricoMerceContext } from '../contexts';

export default function Home() {
  let navigate = useNavigate();

  const {
    resetRicercaData
  } = useContext(CaricoMerceContext)

  const {
    state:{
      visibleMenu,
      group
    },
    getMenuConfig,
  } = useContext(AuthContext);



  React.useEffect(() => {
    resetRicercaData();
    if (visibleMenu.length === 0 || group.length === 0){
      if (localStorage.getItem("user") === null){
        localStorage.clear()
        navigate("/");
      }
      getMenuConfig();
    }
  }, []);

  return (
    <Grid container spacing={2} mt={6} mb={4}>
      { (group.map((res, i) => {

        return(
        
        <Grid item xs={12} md={12} lg={12} key={i}>
          <Card
            elevation={0}
            sx={{ bgcolor: (theme) => theme.palette.background }}
          >
            <List dense={true}>
              <Card
                sx={{
                  border: 1,
                  borderColor: (theme) => theme.palette[res.colorIcon].light,
                  borderTopRightRadius: 5,
                  borderBottomLeftRadius: 0,
                  borderTopLeftRadius: 12,
                  borderBottomRightRadius: 20,
                  backgroundColor: (theme) => theme.palette[res.colorIcon].main,
                  width: "100%",
                  opacity: "0.8",
                }}
              >
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      fontFamily: "inherit",
                    }}
                    primary={res.menuName}
                    ///secondary="Jan 9, 2014"
                  />
                  <ListItemAvatar>
                    <IconButton>{res.icon}</IconButton>
                  </ListItemAvatar>
                </ListItem>
              </Card>
            </List>
            {visibleMenu.length > 0 ? (
              visibleMenu.map((itemMenu, key) => {

                if (itemMenu && res.menuName === itemMenu.subtitle) {
                  return (
                    <Grid item xs={12} md={12} lg={4} key={key}>
                      <Card elevation={0}>
                        <IconBox
                          Icon={itemMenu.icon}
                          navigate={navigate}
                          route={itemMenu.route}
                          colorIcon={itemMenu.colorIcon}
                        />
                        <CardHeader
                          subheader={
                            <Typography fontSize={20} onClick={()=>navigate(itemMenu.route)}>
                              {itemMenu.menuName}
                            </Typography>
                          }
                          sx={{ pl: (theme) => theme.spacing(12) }}
                        />
                        <CardContent component="form"></CardContent>
                      </Card>
                      <VersioningApp />
                    </Grid>
                  );
                } else return null;
              })
            ) : (

              <Grid item xs={12} md={12} lg={12} ml={2} mr={2}>
                <LinearProgress color="primary" />
                <Typography color={"primary"}>Caricamento...</Typography>
              </Grid>
          )}
          </Card>
        </Grid>
      )}))}
    </Grid>
  );
}
