import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import {Card, CardActions, CardContent, CardMedia,  Stack } from '@mui/material';
import { useForm } from '../hooks';
import {isValidLengthPasswod } from '../hooks/useForm';
import { useContext } from 'react';
import { AuthContext } from '../contexts';
import {  useNavigate } from "react-router-dom";
import { useEffect } from 'react';

export default function Login() {

  const {
    login
    } = useContext(AuthContext);


let navigate  = useNavigate()

const validations = [
  ({ pin }) => isValidLengthPasswod(pin) || { pin: '*Lunghezza pin non valida' }
];

const {
  values: loginForm,
  changeHandler,
  touched,
  errors,
  isValid
} = useForm({
  pin: "",
}, validations);


useEffect(() => {
    localStorage.setItem('path', window.location.href)
    let user = localStorage.getItem('user')
    if (user){        
        login(user,navigate);
    }
  }, [])


  const onkeyEnter = (e) => {
    if(e.key === 13){
        e.target.blur();

        login(loginForm.pin,navigate);
    }
  };

  return (
    <Stack direction="row" sx={{ justifyContent: "center" }} mt={10}>
            <Card component="form" sx={{ maxWidth: 400 }} elevation={5}>
                <CardMedia
                    component="img"
                    image={require("../static/logo.png")}
                    alt="logo"
                />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                size="small"
                                fullWidth
                                autoComplete='off'
                                id="pin"
                                label="Pin Access"
                                error={Boolean(touched.pin && errors.pin)}
                                helperText={touched.pin && errors.pin}
                                variant="outlined"
                                autoFocus
                                value={loginForm.pin}
                                onKeyPress={(e)=>onkeyEnter(e)}
                                onChange={(e) => changeHandler("pin", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                    >
                        <Grid
                            item
                            xs={12}
                            textAlign="right"
                            sx={{ pr: (theme) => theme.spacing(1) }}
                        >
                            <Button
                                size="small"
                                type="submit"
                                variant="contained"
                                disabled={!isValid}
                                color="success"
                                onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem('pin', loginForm.pin.substring(0, 3))
                                    login(loginForm.pin,navigate);
                                }}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Stack>
  );
}