import * as React from "react";
import {
  AuthProvider,
  CaricoMerceProvider,
  InventarioProvider,
  MisureColliProvider,
  PosizionamentoProvider,
} from "./contexts";
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Main from "./Main";
import { itIT } from "@mui/material/locale";
import { itIT as itITGrid } from "@mui/x-data-grid";
import { it } from "date-fns/locale";

function App() {
  let theme = createTheme();
  theme = createTheme(
    {
      palette: {
        primary: {
          dark: "#abc4d6",
          main: "#0392ff",
          light: "#00eaff",
        },
        secondary: {
          dark: "#dff7f1",
          main: "#02d6a1",
          light: "#86fcdf",
        },
        success: {
          dark: "#007717",
          main: "#28a745",
          light: "#64da73",
          contrastText: "#fff",
        },
        warning: {
          dark: "#b28704",
          main: "#E0B812",
          light: "#ffcd38",
        },
        error: {
          dark: "#b80000",
          main: "#f40400",
          light: "#ff5834",
          contrastText: "#fff",
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: "0px",
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              color: "black",
            },
          },
        },
      },
    },
    itIT,
    itITGrid
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <MuiThemeProvider theme={theme}>
      <CssBaseline />
        <InventarioProvider>
          <CaricoMerceProvider>
            <PosizionamentoProvider>
              <MisureColliProvider>
                <AuthProvider>
                  <Main></Main>
                </AuthProvider>
              </MisureColliProvider>
            </PosizionamentoProvider>
          </CaricoMerceProvider>
        </InventarioProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
    
  );
}

export default App;
