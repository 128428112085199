import createDataContext from "./createContext";
import { BaseService, ConfigService } from "../api";
import { useUrlClient } from "../hooks";
import { useMenuView } from "../hooks";
import { vociMenu, menuSubheder } from '../utilities';
import { sendNotification } from "../hooks/useNotification";
import { ErrorTitle } from "../components";

const END_LOGIN = "end_login";
const LOGOUT = "logout";
const SET_MENU = "set_menu"
const SET_GROUP = "set_group"
const md5=require("md5");

const INITIAL_STATE = {
  user: null,
  visibleMenu : [],
  group : []
};

const authReducer = (state, action) => {
  switch (action.type) {
   
    case END_LOGIN: {
      const { user } = action.payload;
      return { ...state, user };
    }
    case SET_MENU: {
      const {visibleMenu} = action.payload;
      return { ...state, visibleMenu };
    }
    case SET_GROUP: {
      const {group} = action.payload;
      return { ...state, group };
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
  
    default: {
      return state;
    }
  }
};


const getMenuConfig = (dispatch) => async ( navigate) => {

  const response = await ConfigService.get(`${(await useUrlClient()).baseUrl}/magappconfig`);

  if(response.data){
    //inizializza variabili
    let menuData = null
    let groupsData = null
    //controlla se utente è in file ini o meno
    if (response.data.configurazionemagapp){
      //non file ini
      const configmagapp = response.data.configurazionemagapp
      menuData = configmagapp.visibleMenuMagApp;
      groupsData = configmagapp.visibleMenuMagApp_group;
    }
    else {
      //file ini
      const configmagapp = await useMenuView()
      menuData = configmagapp.visibleMenu;
      groupsData = configmagapp.group;
    }

    let visibleMenu = [];
    menuData.forEach((element) => {
      let voci = {
        ...vociMenu.find((res) => res.idMenu === element.idMenu),
        menuName: element.menuName,
        idMenuGroup: element.idMenuGroup,
      };
      visibleMenu = [...visibleMenu, voci];
    });

    dispatch({ type: SET_MENU, payload: { visibleMenu } });

    let group = [];
    groupsData.forEach((element) => {
      let voci = menuSubheder.find((res) => element.idMenu === res.idMenu);
      if (voci !== undefined){
        group = [...group, voci];
      }
    });
  
    dispatch({ type: SET_GROUP, payload: { group } });
  
    //navigate("/home");
  }
  else{
    sendNotification(
      <ErrorTitle service={"Attenzione!"} />,
      "Elemento non trovato",
      "warning",
      5
    );
  }
};

const login = (dispatch) => async (pin, navigate) => {
    let password ="" 
    pin = pin.toUpperCase()
    localStorage.setItem("user", pin);

  for(let i=0; i< pin.length; i++){
      password = password +"*!"+ pin[i] + "#@"
    }
    localStorage.setItem("password", password);
    const response = await BaseService.post(`${(await useUrlClient()).baseUrl}/login`, {
      jwtusername: pin,
      jwtpassword: md5(password)
    });

    if(response.data){
      const user = response.data;
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
      dispatch({ type: END_LOGIN, payload: { user } });

      //inizializza variabili
      let menuData = null
      let groupsData = null
      //controlla se utente è in file ini o meno
      if (response.data.configurazionemagapp){
        //non file ini
        const configmagapp = response.data.configurazionemagapp
        menuData = configmagapp.visibleMenuMagApp;
        groupsData = configmagapp.visibleMenuMagApp_group;
      }
      else {
        //file ini
        const configmagapp = await useMenuView()
        menuData = configmagapp.visibleMenu;
        groupsData = configmagapp.group;
      }

      let visibleMenu = [];
      menuData.forEach((element) => {
        let voci = {
          ...vociMenu.find((res) => res.idMenu === element.idMenu),
          menuName: element.menuName,
          idMenuGroup: element.idMenuGroup,
        };
        visibleMenu = [...visibleMenu, voci];
      });
      dispatch({ type: SET_MENU, payload: { visibleMenu } });

      let group = [];
      groupsData.forEach((element) => {
        let voci = menuSubheder.find((res) => element.idMenu === res.idMenu);
        if (voci !== undefined){
          group = [...group, voci];
        }
      });
    
      dispatch({ type: SET_GROUP, payload: { group } });
    
      navigate("/home");
    }
};


const loginForDrawer = (dispatch) => async (pin) => {
  
  let password ="" 
  pin = pin.toUpperCase()
  localStorage.setItem("user", pin);

for(let i=0; i< pin.length; i++){
    password = password +"*!"+ pin[i] + "#@"
  }
  localStorage.setItem("password", password);
  const response = await BaseService.post(`${(await useUrlClient()).baseUrl}/login`, {
    jwtusername: pin,
    jwtpassword: md5(password)
  });

  if(response.data){
    const user = response.data;
    localStorage.setItem('token', response.data.token)
    localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
    dispatch({ type: END_LOGIN, payload: { user } });

    //inizializza variabili
    let menuData = null
    let groupsData = null
    //controlla se utente è in file ini o meno
    if (response.data.configurazionemagapp){
      //non file ini
      const configmagapp = response.data.configurazionemagapp
      menuData = configmagapp.visibleMenuMagApp;
      groupsData = configmagapp.visibleMenuMagApp_group;
    }
    else {
      //file ini
      const configmagapp = await useMenuView()
      menuData = configmagapp.visibleMenu;
      groupsData = configmagapp.group;
    }

    let visibleMenu = [];
    menuData.forEach((element) => {
      let voci = {
        ...vociMenu.find((res) => res.idMenu === element.idMenu),
        menuName: element.menuName,
        idMenuGroup: element.idMenuGroup,
      };
      visibleMenu = [...visibleMenu, voci];
    });
    dispatch({ type: SET_MENU, payload: { visibleMenu } });

    let group = [];
    groupsData.forEach((element) => {
      let voci = menuSubheder.find((res) => element.idMenu === res.idMenu);
      if (voci !== undefined){
        group = [...group, voci];
      }
    });
  
    dispatch({ type: SET_GROUP, payload: { group } });
  
  }
};


const logout = (dispatch) => async (navigate) => {
  navigate("/")
  localStorage.clear()
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  dispatch({ type: LOGOUT });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    loginForDrawer,
    logout,
    getMenuConfig
  }, // actions
  INITIAL_STATE // initial state
);
