import {
  Send,
} from "@mui/icons-material";
import {
  Alert,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  Button,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CaricoMerceContext } from "../contexts";
import { useForm } from "../hooks";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { TableRowHtml, ErrorTitle } from "../components";
import { sendNotification } from "../hooks/useNotification";

const MagPartenzaTrazioneDettaglio = (props) => {
  const navigate = useNavigate()
  const { idBarcodeViaggio } = useParams();
  const [customCol, setCustomCol] = React.useState([]);
  const [dialogSegnacolloOpen, setDialogSegnacolloOpen] = React.useState(false)
  const [dialogEliminazioneSegnacolloOpen, setDialogEliminazioneSegnacolloOpen] = React.useState(false);
  const inputRef = React.useRef();

  const {
    state: { 
            columns,
            viaggio,
            infoViaggio,
            messageDialog,
            SegnacolloAppartiene,
            SegnacolloEliminazione
          },
    getPartenzaPerTrazioneData,
    postPartenzaPerTrazione,
    controlloAppartenenzaSegnacollo,
    clearAppartenenzaSegnacollo,
    controlloEliminazioneSegnacollo,
    effettuaEliminazioneSegnacollo,
  } = useContext(CaricoMerceContext);

  useEffect(() => {
    if (SegnacolloAppartiene.Previsto === true) {
      saveData()
    }
    else if (SegnacolloAppartiene.Previsto === false && SegnacolloAppartiene.Inserimento === true) {
      setDialogSegnacolloOpen(true);
    }
    else if(SegnacolloAppartiene.Previsto === false && SegnacolloAppartiene.Inserimento === false){
      sendNotification(
        <ErrorTitle service={"Attenzione!"} />,
        "Impossibile registrare il segnacollo perchè appartiene ad un altro viaggio.",
        "warning",
        5
      );

      setValues({
        RiferimPaletta: "",
      });
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      clearAppartenenzaSegnacollo();
    };
    
  }, [SegnacolloAppartiene]);

  useEffect(() => {
    if (SegnacolloEliminazione.modalview === true) {
      setDialogEliminazioneSegnacolloOpen(true)
    }
    else {
      controlloAppartenenzaSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta); 
    }
  }, [SegnacolloEliminazione]);

  useEffect(() => {
    if (idBarcodeViaggio) {
      getPartenzaPerTrazioneData(idBarcodeViaggio, navigate);
    }
  }, []);

  const validations = [];
  const {
    values: posizioneForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      RiferimPaletta: "",
    },
    validations
  );

  React.useEffect(() => {
    let colonneDaModificare = [];
    columns.map((res, i) => {
      colonneDaModificare = [
        ...colonneDaModificare,
        {
          field: res.id,
          headerName: res.label,
          minWidth: res.minWidth,
        },
      ];
    });
    setCustomCol(colonneDaModificare);
  }, [columns]);

  const onkeyEnter = (event) => {
    if (event === 13) {
      controlloEliminazioneSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta);
    }
  };



  const saveChange = () => {
    controlloEliminazioneSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta);
  };

  const saveData = () => {
    postPartenzaPerTrazione(
      idBarcodeViaggio,
      idBarcodeViaggio,
      posizioneForm.RiferimPaletta
    );
    setValues({
      RiferimPaletta: "",
    });
    setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    handleDialogSegnacolloClose();
  }


  const effettuaEliminazione = () => {
    effettuaEliminazioneSegnacollo(idBarcodeViaggio, posizioneForm.RiferimPaletta);
    setValues({
      RiferimPaletta: "",
    });
    
    //clearEliminazioneSegnacollo()
    handleDialogEliminazioneSegnacolloClose();
  }

  const handleDialogSegnacolloClose = () => {
    setDialogSegnacolloOpen(false);
    clearAppartenenzaSegnacollo();
    setValues({
      RiferimPaletta: "",
    });
  }


  const handleDialogEliminazioneSegnacolloClose = () => {
    setDialogEliminazioneSegnacolloOpen(false);
    //clearEliminazioneSegnacollo();
  }

  return (
    <Grid container>
      {/* <ResponsiveDialog
        openModal={modalApprove}
        setModalApprove={setModalApprove}
        messageDialog={messageDialog}
      /> */}

      <Grid
        container
        sx={{
          position: "fixed",
          background: (theme) => theme.palette.background.paper,
        }}
      >
        <TextField
          id="outlined-basic"
          label="Segnacollo"
          variant="outlined"
          placeholder="Inserire il segnacollo"
          fullWidth
          autoFocus
          autoComplete="off"
          inputRef={inputRef}
          onChange={(e) => changeHandler("RiferimPaletta", e.target.value)}
          value={posizioneForm.RiferimPaletta || ""}
          onKeyPress={(e) => onkeyEnter(e.charCode)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => saveChange()}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid container mt={8}>
        <Grid item xs={12} md={6} lg={6}>
          <Alert severity="info" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.displaytext
                    ? infoViaggio.displaytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }{" "}
          </Alert>
          <Alert severity="warning" component="span">
            {
              <ReactMarkdown
                children={
                  infoViaggio?.summarytext
                    ? infoViaggio.summarytext.toString()
                    : " "
                }
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            }
          </Alert>
          <Grid item xs={12} md={12} lg={12}>
            {customCol?.length > 0 ? (
              <TableRowHtml customCol={customCol} rows={viaggio} />
            ) : (
              <></>
            )}
          </Grid>

        </Grid>
        
      </Grid>
        {/* <Button variant="contained" size="large" sx={{mt:5}}>
          Chiusura carico
        </Button> */}

      <Dialog
        open={dialogSegnacolloOpen}
        //consente chiusura toccando o cliccando al di fuori del popup
        onClose={handleDialogSegnacolloClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma segnacollo non previsto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Il segnacollo non è previsto su questo viaggio.              <br/>
            {SegnacolloAppartiene.InfoMittente !== "" ? (SegnacolloAppartiene.InfoMittente):("")} <br/>
            {SegnacolloAppartiene.InfoDestinatario !== "" ? (SegnacolloAppartiene.InfoDestinatario):("")}<br/>
            Vuoi caricare questo segnacollo sul viaggio?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogSegnacolloClose()}>Non caricare</Button>
          <Button onClick={() => saveData()} autoFocus>
            Carica
          </Button>
        </DialogActions>
      </Dialog>

            {/* dialogo per conferma eliminazione segnacolli già presenti nel viaggio in seguito alla seconda sparatura del segnacollo. */}
      <Dialog
        open={dialogEliminazioneSegnacolloOpen}
        //consente chiusura toccando o cliccando al di fuori del popup
        onClose={handleDialogEliminazioneSegnacolloClose}        
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Conferma segnacollo non previsto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {SegnacolloEliminazione.modalmessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogEliminazioneSegnacolloClose()}>
            Non confermare
          </Button>
          <Button onClick={() => effettuaEliminazione()} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </Grid>
  );
};

export default MagPartenzaTrazioneDettaglio;
